import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import DashboardHeader from "../../../views/Dashboard/DashboardHeader";
import SimpleDashboardSection from "./SimpleDashboardSection";
import { getScores } from "../../../service/scores/dbService";
import Legend from "./Legend";
import NoScoreAvailable from "./NoScoreAvailable";
import { CircularProgress, Button } from "@material-ui/core";
import DetailedDashboardNew from "./DetailedDashboardNew";

// DATA FORMAT:
// const data = [
//   {
//     header: "Overall",
//     description:
//       "Your overall score calculated as an average of your reading, writing, speaking and listening scores.",
//     score: {
//       score: 31,
//       errorBar: 5,
//       selfAssessment: 90,
//     },
//   },
// ];

const modifyFirebaseData = (data) => {
  if (!data) return [];
  const writing = data.find((itm) => itm.header === "Writing");
  if (writing) {
    writing.header = "Writing 1";
    if ("writing_1.7" in writing.details) {
      data.push({
        header: "Writing 2 - Draft 1",
        details: {
          "writing_1.7": writing.details["writing_1.7"],
        },
      });
      writing.details["writing_1.9"] &&
        data.push({
          header: "Writing 2 - Draft 2",
          details: {
            "writing_1.9": writing.details["writing_1.9"],
          },
        });
      delete writing.details["writing_1.7"];
      delete writing.details["writing_1.9"];
    } else if ("writing_1.9" in writing.details) {
      data.push({
        header: "Writing 2 - Draft 1",
        details: {
          "writing_1.9": writing.details["writing_1.9"],
        },
      });
      delete writing.details["writing_1.9"];
    }
  }
  return data;
};

const SimpleDashboard = ({ auth }) => {
  const [detailedView, setDetailedView] = useState(false);
  const [overallData, setOverallData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getScores(auth.uid)
      .then((res) => {
        // Put overall score at top
        let swapIndex = -1;
        res.forEach((itm, idx) => {
          if (itm.header == "Overall") {
            swapIndex = idx;
          }
        });
        if (swapIndex !== -1) {
          let temp = res[swapIndex];
          res[swapIndex] = res[0];
          res[0] = temp;
        }
        setOverallData(res);
        setDetailedData(modifyFirebaseData(res));
      })
      .catch((e) => {
        console.log(e);
        setOverallData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const SimpleDashboardGraphs = () => {
    return (
      <>
        {overallData.map(
          (item) =>
            item.score &&
            item.score.score && (
              <SimpleDashboardSection
                header={item.header}
                description={item.description}
                score={item.score}
              />
            )
        )}
      </>
    );
  };
  return (
    <div>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center", height: "400px" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DashboardHeader />
          {overallData.length && !detailedView && (
            <div style={{ marginLeft: "4rem", marginBottom: "2rem" }}>
              <Legend />
            </div>
          )}
          {overallData.length == 0 && <NoScoreAvailable />}
          {overallData.length && detailedView ? (
            <DetailedDashboardNew data={overallData} />
          ) : (
            <SimpleDashboardGraphs />
          )}
          {overallData.length && (
            <div style={{ height: "7rem" }}>
              <Button
                variant="outlined"
                style={{ float: "right" }}
                onClick={() => setDetailedView(!detailedView)}
              >
                View {detailedView ? "Overall" : "Detailed"} Score
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateTOProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateTOProps)(SimpleDashboard);

import React from "react";
import App from "./App";
import { createStore, applyMiddleware, compose} from "redux";
import { Provider } from "react-redux";
import firebaseConfig  from './config/firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore' ;
import 'firebase/storage';
import 'firebase/functions';
import rootReducer from "./store/reducers/rootReducer";
import thunk from 'redux-thunk';
import { getFirebase, ReactReduxFirebaseProvider }  from 'react-redux-firebase'
import { getFirestore, createFirestoreInstance } from 'redux-firestore';


const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
  };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true}) : compose;


const initialState = {}
const store = createStore(
    rootReducer, 
    initialState,
    composeEnhancer(applyMiddleware( thunk.withExtraArgument({ getFirebase, getFirestore }) ))
  );
  
const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
  };

const FirebaseRedux = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <App />
                </ReactReduxFirebaseProvider>
            </Provider>
        </React.StrictMode>
    );
}

export {db, auth, storage, functions, FirebaseRedux};

import { db } from "../../FirestoreRedux.js";
import firebase from "firebase/app";

const camelToTitleCase = (inputString) => {
  const result = inputString.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Get scores for a given user, if the user has no score, return null.
 * @param {*} userId | String of firestore id in sessions collections
 */
export const getScores = async (userId = "ws7iMEjBN2gR4SZ2gqSoC1Du5Dd2") => {
  let snapShot = await db
    .collection("users")
    .doc(userId)
    .collection("scores")
    .get();
  return snapShot.docs.map((doc) => ({
    header: camelToTitleCase(doc.id),
    score: doc.data().overall,
    details: doc.data().detailed,
  }));
};
